import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Menu from "../components/common/Menu";
import "../css/movieTheater.css";

const MovieTheater = ({ data }) => {
  const movieTheater = data.sanityPage.movieTemp;
  return (
    <div className="movietheater_page_bg">
      <div className="movie_theater">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-3">
              <Link to="/">
                <GatsbyImage
                  image={movieTheater.back.asset.gatsbyImageData}
                  alt={movieTheater.back.asset.altText || "back to home"}
                  className="img-fluid movie_tatto_home"
                  objectFit="contain"
                  loading="eager"
                />
              </Link>
            </div>
            <div className="col-lg-4 col-md-4 col-6">
              <center>
                <Link to="/">
                  <GatsbyImage
                    image={movieTheater.logo.asset.gatsbyImageData}
                    alt={movieTheater.logo.asset.altText || "Gubler Land"}
                    className="img-fluid movie_tatto1"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
              <center>
                <GatsbyImage
                  image={movieTheater.movie.asset.gatsbyImageData}
                  alt={movieTheater.movie.asset.altText || "Movie Theater"}
                  className="img-fluid movie_tatto2"
                  layout="fullWidth"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </center>
              <center>
                <GatsbyImage
                  image={movieTheater.show.asset.gatsbyImageData}
                  alt={movieTheater.show.asset.altText || "Now Showing"}
                  className="img-fluid movie_tatto3"
                  layout="fullWidth"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </center>
            </div>
            <div className="col-lg-4 col-md-4 col-3">
              <Menu />
            </div>
          </div>
          <div className="clearfix movie_tatoo_2row"> </div>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <center>
                <Link to={movieTheater.directed.slug.current}>
                  <GatsbyImage
                    image={movieTheater.directed.asset.gatsbyImageData}
                    alt={movieTheater.directed.title || "Stuff I Directed"}
                    className="img-fluid movie_tatto5"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
            </div>
            <div className="col-lg-4 col-md-4">
              <center>
                <Link to={movieTheater.acted.slug.current}>
                  <GatsbyImage
                    image={movieTheater.acted.asset.gatsbyImageData}
                    alt={movieTheater.acted.title || "Stuff I acted"}
                    className="img-fluid movie_tatto6"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
            </div>
            <div className="col-lg-4 col-md-4">
              <center>
                <Link to={movieTheater.coming.slug.current}>
                  <GatsbyImage
                    image={movieTheater.coming.asset.gatsbyImageData}
                    alt={movieTheater.coming.title || "Coming soon"}
                    className="img-fluid movie_tatto7"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query MyMovieTheaterQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      movieTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        movie {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        show {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        directed {
          title
          slug {
            current
          }
          alt
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        acted {
          title
          slug {
            current
          }
          alt
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        coming {
          title
          slug {
            current
          }
          alt
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
      }
    }
  }
`;
export default MovieTheater;
